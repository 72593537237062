import React, { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { showToast } from '../../Utils/Utility';
import { confirmPopup } from 'primereact/confirmpopup';
import { TbCopy, TbInfoCircle, TbTrash } from 'react-icons/tb';
import { FiDownload } from 'react-icons/fi';
import { EOverlayStyles } from '../events/EventsOverlay/EventsOverlayStyles';
import { Toast } from 'primereact/toast';
import { UploadDeleteService } from '../../service/uploadService';
import { LStyles } from '../events/EventsLog/eventlogsStyles';

interface ActionButtonsProps {
    rowData: {
        generation: string;
        metadata: {
            folder: string;
            filenameInBucket: string;
            filename: string;
        };
    };
    handleDownload: (rowData: ActionButtonsProps['rowData']) => void;
    handleChildEvent: (type: string, filename: string) => void;
    setIsLoading: (isLoading: boolean) => void;
    pageTitle: string;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ rowData, handleDownload, handleChildEvent, setIsLoading, pageTitle }) => {
    const op = useRef<OverlayPanel>(null);
    const toast = useRef<Toast>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(rowData.generation)
            .then(() => {
                showToast(toast, "success", "Id copied to clipboard", "", false);
            })
            .catch((err) => {
                showToast(toast, "error", `Failed to copy tooltip content : ${err}`, "", false);
            });
    };

    const deleteService = async (rowData: ActionButtonsProps['rowData']) => {
        setIsLoading(true);
        await UploadDeleteService(
            rowData.metadata.folder,
            rowData.metadata.filenameInBucket,
            rowData.generation
        )
            .then(() => {
                handleChildEvent("Cancelled", rowData.metadata.filename);
            })
            .catch((error) => {
                showToast(toast, "error", "Tariff Cancellation Failed " + error, "", false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const confirmDelete = (event: React.MouseEvent<HTMLButtonElement>, rowData: ActionButtonsProps['rowData']) => {
        if (isDialogOpen) return;
        setIsDialogOpen(true);
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to delete this file?',
            icon: <TbTrash />,
            defaultFocus: 'reject',
            accept: () => {
                deleteService(rowData);
                setIsDialogOpen(false);
            },
            reject: () => {
                setIsDialogOpen(false);
            },
            onHide: () => {
                setIsDialogOpen(false);
            },
            acceptClassName: 'p-button-danger',
            acceptLabel: "Yes",
        });
    };

    return (
        <div className="grid m-1">
            <Button
                text
                icon={<FiDownload className="ford-primary" />}
                onClick={() => handleDownload(rowData)}
                aria-label="download"
                className='border-round'
            />
            {pageTitle !== "MnV Data" ? 
            <Button
            text
            icon={<TbTrash />}
            onClick={(e) => confirmDelete(e, rowData)}
            className="p-button-danger border-round"
            aria-label="trash"
            />
            :''}
            <Button
                text
                icon={<TbInfoCircle />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => op.current?.toggle(e)}
                aria-label="info"
                data-testid="info-button"
                className='border-round'
            />
            <OverlayPanel
                ref={op}
                className="text-sm fadein animation-duration-100"
                closeOnEscape
                dismissable
                pt={{ content: { className: 'p-2' } }}
            >
                <LStyles.StyledTable>
                    <tbody>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>
                                Generation ID
                            </EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.generation}</span>
                                <button
                                    className="ml-2 surface-0 border-none cursor-pointer"
                                    onClick={handleCopy}
                                    aria-label="copy"
                                    data-testid="copy-button" 
                                >
                                    <TbCopy />
                                </button>
                                
                            </EOverlayStyles.CellStyle>
                        </tr>
                    </tbody>
                    </LStyles.StyledTable>
            </OverlayPanel>
        </div>
    );
};