import React from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { EStyles } from '../../events/EventsForm/eventsformStyles';

type CheckboxWithLabelProps = {
  id: string;
  label: string;
  checked: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
  dataTestId?: string;
  disabled?: boolean;
};

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  id,
  label,
  checked,
  onChange,
  dataTestId,
  disabled = false,
}) => {
  return (
    <EStyles.CheckboxContainer>
      <Checkbox
        data-testid={dataTestId}
        inputId={id}
        name={id}
        checked={checked}
        onChange={(e) => onChange(e)}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </EStyles.CheckboxContainer>
  );
};
