import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorStyles } from './errorStyles';
import { errorMessage } from '../../const/app.constants';

const ErrorPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/');
    };

    return (
        <ErrorStyles.Container>
            <ErrorStyles.Title>{errorMessage.title}</ErrorStyles.Title>
            <ErrorStyles.Message>{errorMessage.message}</ErrorStyles.Message>
            <ErrorStyles.HomeButton onClick={handleButtonClick}>{errorMessage.buttonText}</ErrorStyles.HomeButton>
        </ErrorStyles.Container>
    );
};

export default ErrorPage;