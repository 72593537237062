export type formfieldstestcomponent = {
	id: string;
	label: string;
	title: string;
	type: unknown;
}

export type ScheduleFormValue = {
	scheduleTime: Date | null;
	target: string | null;
	vtnName: string;
	beginDate: Date | null;
	endDate: Date | null;
	signalName: string;
	area: string;
	signalPayload: number | null;
	testEvent: boolean;
	duration: string;
  };

  export const ScheduleInitialFormValue: ScheduleFormValue = {
	scheduleTime: null,
	target: null,
	vtnName: 'FORD_VTN_SIMULATOR',
	beginDate: null,
	endDate: null,
	signalName: 'SIMPLE',
	area: '48216',
	signalPayload: null,
	testEvent: false,
	duration: 'PT60',
  };