import { useState, useEffect } from 'react';

interface UserProfile {
  username: string;
  email: string;
  userid: string;
  expiry: number;
}

interface ProfileData {
  status?: number;
  data?: UserProfile;
}

interface ErrorResponse {
  response?: {
    data?: {
      message: string;
    };
  };
}

export const useSessionValidation = (validateService: () => Promise<ProfileData>) => {
  const [profileData, setProfileData] = useState<UserProfile>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const validateSession = async () => {
      setIsLoading(true);
      try {
        const response = await validateService();
        if (response?.status === 200) {
          if (response.data) {
            setProfileData(response.data);
          }
        }
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        console.log(err?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };

    validateSession();
  }, [validateService]);

  return { profileData, isLoading, setIsLoading };
};
