import moment from "moment";
import { getSignalNameByKey } from "../types/signalValues";
import { ScheduleLogDto } from "../types/schedule";
import { EventDto } from "../types/event";
import { formatDateMoment } from "./Utility";
import { EventDeliveryDto } from "../types/event.delivery";
import { ScheduleFormValue } from "../types/schedule.formfields";
import { EventFormValue } from "../types/event.formfields";

export const responseAcknowledgedTemplateForEvent = (rowData: EventDeliveryDto) => {
    return rowData?.response_acknowledged ? 'Success' : 'Failed';
};

export const responseCodeTemplateForEvent = (rowData: EventDto) => {
    return rowData?.response_code ? rowData?.response_code : 'NULL';
};
export const responseCreatedDateTemplateForEvent = (rowData: EventDto) => {
    return formatDateMoment(rowData?.created_at);
};

export const responseScheduleDateTemplate = (rowData: ScheduleLogDto) => {
    return rowData?.schedule ? moment(rowData?.schedule).format('MM/DD/YYYY, HH:mm') : 'NULL';
};

export const responseStartDateTemplate = (rowData: ScheduleLogDto) => {
    return formatDateMoment(rowData?.payload.start_time);
};

export const responseStartDateTemplateForEvent = (rowData: EventDto) => {
    return formatDateMoment(rowData?.start_time);
};

export const responseEndDateTemplate = (rowData: ScheduleLogDto) => {
    return formatDateMoment(rowData?.payload.end_time);
};

export const responseEndDateTemplateForEvent = (rowData: EventDto) => {
    return formatDateMoment(rowData?.end_time);
};

export const responsePayloadTemplate = (rowData: ScheduleLogDto) => {
    return getSignalNameByKey(rowData.payload.signal_payload);
};

export const responsePayloadTemplateForEvent = (rowData: EventDto) => {
    return getSignalNameByKey(rowData.signal_payload);
};

export function validateSchedulePayload(formValue: ScheduleFormValue): { isValid: boolean; errorMessage?: string } {
    for (const [key, value] of Object.entries(formValue)) {
      if (value === null || value === '') {
        return { isValid: false, errorMessage: 'Please enter all the required input fields' };
      }
    }
  
    const currentTime = new Date();
    const { scheduleTime, beginDate, endDate } = formValue;
  
    if (scheduleTime && scheduleTime <= currentTime) {
      return { isValid: false, errorMessage: 'Schedule time should be greater than the current time' };
    }
  
    if (scheduleTime && beginDate && scheduleTime > beginDate) {
      return { isValid: false, errorMessage: 'Schedule time should be before event start date' };
    }
  
    if (beginDate && endDate && beginDate > currentTime && endDate > beginDate) {
      return { isValid: true };
    } else {
      return { isValid: false, errorMessage: 'Please check the calendar inputs' };
    }
  }

  export function validateEventPayload(formValue: EventFormValue): { isValid: boolean; errorMessage?: string } {
    const isNull = Object.values(formValue).some(value => value === null || value === "");
    if (isNull) {
        return { isValid: false, errorMessage: 'Please enter all the required input fields' };
    } else {
        if (
            formValue['beginDate'] &&
            formValue['endDate'] &&
            formValue['beginDate'] > new Date() &&
            formValue['endDate'] > formValue['beginDate']
        ) {
            return { isValid: true };
        } else {
            return { isValid: false, errorMessage: 'Please check the calendar inputs' };
        }
    }
}