import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { TbInfoCircle } from 'react-icons/tb';
import { EOverlayStyles } from './EventsOverlayStyles';
import { LStyles } from '../EventsLog/eventlogsStyles';

type FlattenedItem = {
  key: string;
  value: string;
};

type ResponseObject = [string, string?] | null;

export const flattenData = (data: Record<string, unknown>, maxDepth = 3): FlattenedItem[] => {
  const result: FlattenedItem[] = [];

  const processValue = (key: string, value: unknown, depth: number) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        flatten(item as Record<string, unknown>, `${key}[${index}]`, depth + 1);
      });
    } else if (typeof value === 'object' && value !== null && depth < maxDepth) {
      flatten(value as Record<string, unknown>, key, depth);
    } else {
      const shortKey = key.split('.').pop()?.split('[').shift() || '';
      result.push({ key: shortKey, value: value?.toString() || '' });
    }
  };

  const flatten = (obj: Record<string, unknown>, path = '', depth = 0) => {
    Object.entries(obj).forEach(([key, value]) => {
      const newPath = path ? `${path}.${key}` : key;
      processValue(newPath, value, depth);
    });
  };

  flatten(data);
  return result;
};

interface ResponseOverlayProps {
  parsedResponse: Record<string, unknown> | null;
}

export const ResponseOverlay: React.FC<ResponseOverlayProps> = ({ parsedResponse }) => {
  const op = useRef<OverlayPanel | null>(null);

  const handleMouseMove = () => {
    if (op.current) {
      op.current.hide();
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleMouseMove, true);
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    op.current?.toggle(e);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleMouseMove, true);
  };

  const renderContent = (data: Record<string, unknown>) => {
    const flattenedData = flattenData(data);

    return (
      <LStyles.StyledTable>
        <tbody>
          {flattenedData.map((item) => (
            <tr key={item.key}>
              <EOverlayStyles.KeyCellStyle>{item.key}</EOverlayStyles.KeyCellStyle>
              <EOverlayStyles.CellStyle>{item.value}</EOverlayStyles.CellStyle>
            </tr>
          ))}
        </tbody>
      </LStyles.StyledTable>
    );
  };

  return (
    <>
      <OverlayPanel ref={op} className='text-sm fadein animation-duration-100'>
        {parsedResponse ? renderContent(parsedResponse) : "NULL"}
      </OverlayPanel>
      <Button rounded text icon={<TbInfoCircle />} onClick={handleButtonClick}/>
    </>
  );
};

export const parseResponseObject = (responseObject: ResponseObject): Record<string, unknown> | null => {
  if (!Array.isArray(responseObject)) {
    return null;
  }

  if (!responseObject[1]) {
    return null;
  }

  if (responseObject[0] === "Not Found") {
    return null;
  }

  try {
    return JSON.parse(responseObject[1]);
  } catch (error) {
    return null;
  }
};

interface ResponseObjectTemplateProps {
  rowData: {
    response_object: ResponseObject;
  };
}

export const ResponseObjectTemplate: React.FC<ResponseObjectTemplateProps> = ({ rowData }) => {
  const parsedResponse = parseResponseObject(rowData?.response_object);

  if (!parsedResponse) {
    const responseText = rowData?.response_object ? rowData?.response_object[0] || 'NULL' : 'NULL';
    return <span>{responseText}</span>;
  }

  return (
    <div className='flex align-content-center'>
      {rowData?.response_object ? rowData.response_object[0]?.toLowerCase() || 'NULL' : 'NULL'}
      {parsedResponse ? <ResponseOverlay parsedResponse={parsedResponse} /> : ""}
    </div>
  );
};

export default ResponseObjectTemplate;
