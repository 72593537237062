import React, { FunctionComponent } from "react";
import { ClipLoader } from "react-spinners";
import { AuthService } from "../../service/authService";
import { IAuthService, useAuth } from "../../Utils/useAuth";

const authService: IAuthService = {
  getAuthUrl: AuthService,
};

export const Auth: FunctionComponent = () => {
  const { loader, error } = useAuth(authService);

  return (
    <div className="loader">
      {loader && (
        <ClipLoader
          color="var(--primary-ui)"
          loading={loader}
          size={100}
          aria-label="Publishing"
          data-testid="loader"
          className="loader-img"
        />
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Auth;