export const theme = {
    colors: {
        primary: '#3E5366',
        background: '#000',
        text: '#FFF',
    },
};

interface Theme {
    colors: {
        primary: string;
        background: string;
        text: string;
    };
}

export function applyTheme(theme: Theme) {
    const root = document.documentElement;

    root.style.setProperty('--primary-ui', theme.colors.primary);
    root.style.setProperty('--text-color', theme.colors.text);
    root.style.setProperty('--background-color', theme.colors.background);
}

applyTheme(theme);