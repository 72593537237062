
export const vens = [
    {name:'FORD_VEN',value: 'FORD_VEN'}
];

export const signalNames = [
    { name: 'SIMPLE', value: 'SIMPLE'}
];

export const EventPayload = {
    target: null, 
    vtnName: "FORD_VTN_SIMULATOR", 
    beginDate: null, 
    endDate: null, 
    area: '48216',
    signalName: 'SIMPLE', 
    signalPayload: null, 
    testEvent: false, 
    duration: "PT60"
}
