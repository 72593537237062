import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { TieredMenu } from 'primereact/tieredmenu';
import { SStyles } from './sidebarStyles';
import { validateService } from '../../service/authService';
import { SidebarData } from './sidebardata';
import { IoIosMenu } from 'react-icons/io';
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { useSessionValidation } from '../../Utils/useSessionValidation';

export const Sidebar: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const iconContextValue = useMemo(() => ({ size: '2.25rem' }), []);

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth < 450);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 445);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { profileData } = useSessionValidation(validateService);


  const menu = useRef<TieredMenu>(null);
  const items = [
    {
      label: 'Tariffs',
      url: '/tariffs',
      icon: <LiaFileInvoiceDollarSolid className='p-1' />
    },
  ];

  const filteredSidebarData = isSmallScreen
    ? SidebarData?.filter((item) => item?.title !== 'TARIFFS')
    : SidebarData;

  return (
    <>
      <IconContext.Provider value={iconContextValue}>
        <SStyles.SidebarContainer>
          <SStyles.SidebarWrap>
            {profileData ?
              <>
                {
                  filteredSidebarData?.map((item) => (
                    <SStyles.SidebarLink
                      key={item.title}
                      onMouseOver={() => setHover(true)}
                      onClick={() => navigate(item.path)}
                      className="sm:justify-content-center"
                    >
                      <SStyles.SidebarLinkWrapper>
                        <SStyles.SidebarIcon $hover={hover}>{React.createElement(item.icon)}</SStyles.SidebarIcon>
                        <SStyles.SidebarLabel className="p-1 text-center">
                          {item.title}
                        </SStyles.SidebarLabel>
                      </SStyles.SidebarLinkWrapper>
                    </SStyles.SidebarLink>
                  ))
                }
                {isSmallScreen ?
                  <div className="card flex justify-content-center">
                    <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
                    <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => menu.current?.toggle(e)} style={{ background: "transparent", border: "none", color: "white" }}><IoIosMenu /></button>
                  </div>
                  : ''
                }
              </>
              : (
                ''
              )}
          </SStyles.SidebarWrap>
        </SStyles.SidebarContainer>
      </IconContext.Provider>

    </>
  )
}