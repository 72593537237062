import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { EStyles } from '../../events/EventsForm/eventsformStyles';

type Payload = {
  key: number;
  name: string;
};

type SignalPayloadSelectorProps = {
  signalPayloadValues: Payload[];
  selectedPayload: Payload | null;
  setSelectedPayload: React.Dispatch<React.SetStateAction<Payload | null>>;
  formValue: any;
  setFormValue: React.Dispatch<React.SetStateAction<any>>;
};

export const SignalPayloadSelector: React.FC<SignalPayloadSelectorProps> = ({
  signalPayloadValues,
  selectedPayload,
  setSelectedPayload,
  formValue,
  setFormValue,
}) => {
  const handleRadioBoxChange = (
    value: Payload
  ) => {
    setSelectedPayload(value);
    setFormValue((prev: any) => ({
      ...prev,
      signalPayload: value.key,
    }));
  };

  return (
    <EStyles.SignalPayloadContainer>
      {signalPayloadValues.map((payloadValues) => (
        <div key={payloadValues.key} className="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3">
          <div className="flex align-items-center">
            <RadioButton
              inputId={payloadValues.name}
              name="signalPayload"
              value={payloadValues}
              onChange={(e) => handleRadioBoxChange(e.value)}
              checked={selectedPayload ? selectedPayload.key === payloadValues.key : false}
              data-testid={`signal-payload-radio-${payloadValues.name}`}
            />
            <label htmlFor={payloadValues.name} className="ml-2" data-testid="signal-payload-label">
              {payloadValues.name}
            </label>
          </div>
        </div>
      ))}
    </EStyles.SignalPayloadContainer>
  );
};
