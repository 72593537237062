import React from 'react';
import { sidebarItem } from '../../types/sidebar';
import { TbHome, TbList } from "react-icons/tb";
import { BsClockHistory } from "react-icons/bs";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
export const SidebarData: sidebarItem[] = [
    {
        title: 'HOME',
        path: '/',
        icon: TbHome
        
    },
    {
        title: 'EVENTS',
        path: '/events',
        icon: TbList
        
    },
    {
        title: 'SCHEDULES',
        path: '/schedules',
        icon: BsClockHistory
    },
    {
        title: 'TARIFFS',
        path: '/tariffs',
        icon: LiaFileInvoiceDollarSolid
    }
];