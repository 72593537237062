
export interface EventFormValue {
	target: string | null;
	vtnName: string;
	beginDate: Date | null;
	endDate: Date | null;
	signalName: string;
	area: string;
	signalPayload: number | null;
	testEvent: boolean;
	duration: string;
	schedule? : string | undefined;
	[key: string]: string | number | boolean | Date | null | undefined;
}

export const EventInitialFormValue: EventFormValue = {
    target: null,
    vtnName: 'FORD_VTN_SIMULATOR',
    beginDate: null,
    endDate: null,
    signalName: 'SIMPLE',
    area: '48216',
    signalPayload: null,
    testEvent: false,
    duration: 'PT60',
  };
