import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { TbInfoCircle, TbCopy, TbTrash } from 'react-icons/tb';
import { EOverlayStyles } from '../EventsOverlay/EventsOverlayStyles';
import { ColumnData, getCommonColumn, getEventColumn, getScheduleColumn } from '../../../types/event';
import { LStyles } from '../EventsLog/eventlogsStyles';
import { showToast } from '../../../Utils/Utility';

interface RequestIdTemplateProps {
  rowData: RowData;
  onConfirm?: (rowData: RowData) => void;
  type?: string;
  toastRef?: React.RefObject<Toast | null>;
}

interface RowData extends ColumnData {
  request_id: string;
  status: string;
  id: string;
}

const renderRows = (data: { key: string, value: string }[]) => {
  return data.map((item, index) => (
    <tr key={index}>
      <EOverlayStyles.KeyCellStyle>{item.key}</EOverlayStyles.KeyCellStyle>
      <EOverlayStyles.CellStyle>
        <span>{item.value}</span>
      </EOverlayStyles.CellStyle>
    </tr>
  ));
};

export const RequestIdTemplate: React.FC<RequestIdTemplateProps> = ({ rowData, onConfirm, type, toastRef }) => {
  const toast = useRef<Toast>(null);
  const op = useRef<OverlayPanel>(null); // Assuming OverlayPanel is the correct type

  const handleCopy = () => {
    navigator.clipboard
      .writeText(rowData.request_id)
      .then(() => {
        showToast(toast, "success", "Id copied to clipboard", "", false);
      })
      .catch((err) => {
        showToast(toast, "error", `Failed to copy tooltip content : ${err}`, "", false);
      });
  };

  const isDeleteDisabled =
    rowData.status === 'PUBLISHED' || rowData.status === 'CANCELLED' || rowData.status === 'FAILED';

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Button
        rounded
        text
        data-testid="info-button"
        icon={<TbInfoCircle />}
        className='h-auto'
        onClick={(e) => op.current?.toggle(e)}
      />
      <OverlayPanel
        ref={op}
        className="text-sm fadein animation-duration-100"
        closeOnEscape
        dismissable
        pt={{ content: { className: 'p-2' } }}
      >
        <LStyles.StyledTable>
          <tbody>
            <tr>
              <EOverlayStyles.KeyCellStyle>Request ID</EOverlayStyles.KeyCellStyle>
              <EOverlayStyles.CellStyle>
                <span>{rowData.request_id}</span>
                <button
                  className="ml-2 surface-0 border-none cursor-pointer"
                  onClick={handleCopy}
                  data-testid="copy-button"
                >
                  <TbCopy />
                </button>
              </EOverlayStyles.CellStyle>
            </tr>
            {renderRows(getCommonColumn(rowData))}
            {type === 'event' ? renderRows(getEventColumn(rowData).map(item => ({ ...item, value: item.value ?? '' }))) : renderRows(getScheduleColumn(rowData).map(item => ({ ...item, value: item.value ?? '' })))}
          </tbody>
        </LStyles.StyledTable>
      </OverlayPanel>
      {onConfirm && (
        <Button
          disabled={isDeleteDisabled}
          text
          icon={<TbTrash />}
          onClick={() => onConfirm(rowData)}
          className="p-button-danger"
          id={`delete-${rowData.id}`}
        />
      )}
    </div>
  );
};
