import axios, { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';

const BFF_URL = window.__RUNTIME_CONFIG__?.REACT_APP_BFF_URL || 'http://localhost:8080';

const axiosInstance = axios.create({
  baseURL: `${BFF_URL}/grid-connect/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const responseHandler = (response: AxiosResponse, toast: React.RefObject<Toast>) => {
  const statusCode = response.status;
  if (statusCode === 403) {
    window.location.replace('/');
    return response;
  }
  if (statusCode === 401) {
    return Promise.reject(response);
  } else if (statusCode === 200 || statusCode === 201 || statusCode === 204) {
    return response;
  } else if (statusCode === 400) {
    console.log(response?.data?.error);
    setTimeout(() => {   
      toast.current?.show({
        severity: 'warn',
        summary: 'Warning',
        detail:
          response?.data.error?.error ??
          response?.data?.error?.message ??
          'Unknown error',
        life: 3000,
      });
    }, 500);
    return response;
  } else {
    window.location.replace('/error');
    return Promise.reject(new Error('Unexpected status code'));
  }
};

export const setupAxiosInterceptors = (toast: React.RefObject<Toast>) => {
  axiosInstance.interceptors.response.use(
    (response) => responseHandler(response, toast),
  );
};

export default axiosInstance;
