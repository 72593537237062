import { useState } from 'react';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Payload } from '../../../types/signalValues';
import { EventFormValue } from '../../../types/event.formfields';

export const useEventFormHandlers = (initialFormValue: EventFormValue) => {
  const [formValue, setFormValue] = useState<EventFormValue>(initialFormValue);
  const [selectedPayload, setSelectedPayload] = useState<Payload | null>(null);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement> | DropdownChangeEvent
  ) => {
    const { id, value } = e.target as HTMLInputElement | { id: string; value: any };
    setFormValue((prevFormValue: EventFormValue) => ({
      ...prevFormValue,
      [id]: value,
    }));
  };

  const handleCheckBoxChange = (id: keyof EventFormValue, checked: boolean) => {
    setFormValue((prevFormValue: EventFormValue) => ({
      ...prevFormValue,
      [id]: checked,
    }));
  };

  const handleRadioBoxChange = (value: Payload) => {
    let payload = { ...formValue };
    payload["signalPayload"] = value.key;
    setFormValue(payload);
    setSelectedPayload(value);
};

  return {
    formValue,
    setFormValue,
    handleFormChange,
    handleCheckBoxChange,
    handleRadioBoxChange,
    selectedPayload,
    setSelectedPayload
  };
};

export function isFutureDate(date: Date) {
    const today = new Date();
    const tempDate = new Date(date.getTime());
    today.setHours(0, 0, 0, 0);
    tempDate.setHours(0, 0, 0, 0);
    return tempDate > today;
  }