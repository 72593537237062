import React from 'react';
import { Dialog } from 'primereact/dialog';

interface ProfileData {
    username: string;
    email: string;
}

interface ProfileDialogProps {
    visible: boolean;
    onHide: () => void;
    profileData: ProfileData;
}

export const ProfileDialog: React.FC<ProfileDialogProps> = ({ visible, onHide, profileData }) => {
    return (
        <Dialog header="Profile Details" visible={visible} style={{ width: '50vw' }} onHide={onHide} pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
            <div>
                <p><strong>Name:</strong> {profileData?.username}</p>
                <p><strong>Email:</strong> {profileData?.email}</p>
            </div>
        </Dialog>
    );
};