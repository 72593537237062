import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LStyles } from '../events/EventsLog/eventlogsStyles';
import { Dialog } from 'primereact/dialog';
import {CSVReader} from '../../Utils/fileUpload';
import { ErrorStyles } from '../error/errorStyles';
import { ClipLoader } from 'react-spinners';
import { StartStyles } from './startpageStyles';
import { Toast } from 'primereact/toast';
import { useFetchTariffs } from '../../Utils/useFetchTariff';
import { useSessionValidation } from '../../Utils/useSessionValidation';
import { useEventHandling } from './useToastHandling';
import { validateService } from '../../service/authService';

const StartPage = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState<string>()
  const { publishedEventId, setPublishedEventId } = useFetchTariffs();
  const { profileData, isLoading, setIsLoading } = useSessionValidation(validateService);
  const { toast } = useEventHandling(publishedEventId || '', setPublishedEventId);

  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const handleDialog = (title: string) => {
    setVisible(true);
    setDialogTitle(title);
  };

  const handleChildEvent = (requestId: string, filename: string) => {
    setIsLoading(false);
    setPublishedEventId(requestId + filename);
  };

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <LStyles.EventsLogContainer>
        <ErrorStyles.Container>
          {isLoading ? (
            <div className="loader">
              <ClipLoader
                color="var(--primary-ui)"
                loading={isLoading}
                size={100}
                aria-label="Publishing"
                data-testid="loader"
                className="loader-img"
              />
            </div>
          ) : (
            ''
          )}
          <StartStyles.HeaderText>Grid Connect Dashboard</StartStyles.HeaderText>
          {!isLoading ? (
            <>
              {profileData?.username ? (
                <>
                  <LStyles.CustomButton onClick={() => handleButtonClick('/events')} className="m-2 w-13rem">
                    Demand Response
                  </LStyles.CustomButton>
                  <LStyles.CustomButton onClick={() => handleDialog('Upload Variable Tariff Rate File')} className="m-2 w-13rem">
                    Upload Variable Tariff
                  </LStyles.CustomButton>
                  <LStyles.CustomButton className="m-2 w-13rem">View Customer Data</LStyles.CustomButton>
                  <Dialog
                    visible={visible}
                    className={'w-6'}
                    header={dialogTitle}
                    modal
                    onHide={hideDialog}
                    maximizable={window.innerWidth < 768 ? false : true}
                    maximized={window.innerWidth < 768 ? true : false}
                    dismissableMask
                    pt={{ header: { className: 'p-2 pl-5' }, headerTitle: { className: 'font-bold' } }}
                  >
                    <CSVReader onPublishEvent={handleChildEvent} setVisible={setVisible} setLoader={setIsLoading} />
                  </Dialog>
                </>
              ) : (
                <>
                  {!profileData?.username ? (
                    <LStyles.CustomButton className="m-3 w-10rem" onClick={() => navigate('/login')}>
                      Login
                    </LStyles.CustomButton>
                  ) : null}
                </>
              )}
            </>
          ) : (
            ''
          )}
        </ErrorStyles.Container>
      </LStyles.EventsLogContainer>
    </>
  );
};

export default StartPage;
