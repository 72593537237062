import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore, MdFirstPage, MdLastPage } from 'react-icons/md';
import { PaginatorTemplateOptions } from 'primereact/paginator';
import { PaginatorChangeEvent } from 'primereact/paginator';

const UseIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 610);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 610);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

interface PageLinks {
    prev?: boolean;
    first?: boolean;
    last?: boolean;
    next?: boolean;
}

export const createPaginatorTemplate = (

    page: number,
  
    rows: number,
  
    totalRecords: number,
  
    pageLinks: PageLinks,

    setPage: (page: number) => void,
  
    setRows: (rows: number) => void
  
  ): PaginatorTemplateOptions => {

    const isMobile = UseIsMobile();

    interface PaginatorRowsPerPageDropdownOptions {
        value: number;
        options: { label: number; value: number }[];
        onChange: (event: PaginatorChangeEvent) => void;
    }

    interface PaginatorTemplate {
        layout: string;
        RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => JSX.Element;
        CurrentPageReport: () => JSX.Element | null;
        PageLinks: () => JSX.Element;
        PrevPageLink: () => JSX.Element;
        FirstPageLink: () => JSX.Element | null;
        LastPageLink: () => JSX.Element | null;
        NextPageLink: () => JSX.Element;
    }

    return {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',

        RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
            ];

            return (
                <>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.value)} />
                </>
            );
        },
        CurrentPageReport: () => {
            if (isMobile) return null;

            const first = (page - 1) * rows + 1;
            const last = Math.min(page * rows, totalRecords);

            return (
                <span className="mr-2 select-none text-center ">
                    Showing {first} to {last} of {totalRecords} events
                </span>
            );
        },
        PageLinks: () => {
            const totalPages = Math.ceil(totalRecords / rows);
            const pages = [];

            if (page > 1) {
                pages.push(
                    <button
                        key="prev"
                        type="button"
                        className="p-paginator-page p-paginator-element p-link"
                        onClick={() => setPage(page - 1)}
                    >
                        {page - 1}
                    </button>
                );
            }

            pages.push(
                <button
                    key="current"
                    type="button"
                    className="p-paginator-page p-paginator-element p-link p-highlight"
                    onClick={() => setPage(page)}
                >
                    {page}
                </button>
            );

            for (let i = 1; i <= 2; i++) {
                if (page + i <= totalPages) {
                    pages.push(
                        <button
                            key={`next-${i}`}
                            type="button"
                            className="p-paginator-page p-paginator-element p-link"
                            onClick={() => setPage(page + i)}
                        >
                            {page + i}
                        </button>
                    );
                }
            }

            return <>{pages}</>;
        },
        PrevPageLink: () => {
            return (
                <Button
                    className={`bg-transparent ${!pageLinks?.prev ? 'customDisableButton' : ''}`}
                    onClick={() => setPage(page - 1)}
                    disabled={!pageLinks?.prev}
                >
                    <MdOutlineNavigateBefore className="w-2rem text-900" />
                </Button>
            );
        },
        FirstPageLink: () => {
            if (isMobile) return null;

            return (
                <Button
                    className={`bg-transparent ${!pageLinks?.first ? 'customDisableButton' : ''}`}
                    onClick={() => setPage(1)}
                    disabled={!pageLinks?.first}
                >
                    <MdFirstPage className="w-2rem text-900" />
                </Button>
            );
        },
        LastPageLink: () => {
            if (isMobile) return null;

            const totalPages = Math.ceil(totalRecords / rows);
            return (
                <Button
                    className={`bg-transparent ${!pageLinks?.last ? 'customDisableButton' : ''}`}
                    onClick={() => setPage(totalPages)}
                    disabled={!pageLinks?.last}
                >
                    <MdLastPage className="w-2rem text-900" />
                </Button>
            );
        },
        NextPageLink: () => {
            return (
                <Button
                    className={`bg-transparent ${!pageLinks?.next ? 'customDisableButton' : ''}`}
                    onClick={() => setPage(page + 1)}
                    disabled={!pageLinks?.next}
                >
                    <MdOutlineNavigateNext className="w-2rem text-900" />
                </Button>
            );
        },
    } as PaginatorTemplate;
};