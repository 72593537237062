import { formatDateMoment } from "../Utils/Utility";
import {EventDeliveryDto} from "./event.delivery";

export interface BaseEvent {
  ven_id: string;
  vtn_name: string;
  request_id: string;
  signal_name: string;
  signal_payload: number;
  start_time: string;
  end_time: string;
  duration: string;
  area_code: string;
  response_required: boolean;
  test_event: boolean;
  time_zone: string;
  published_at: string;
  created_by: string;
}

export interface EventDto extends BaseEvent {
  id: string;
  response_code: string;
  created_at: string;
  delivery_logs: EventDeliveryDto[];
}

export interface ColumnData {
  payload: BaseEvent;
  vtn_name?: string;
  duration?: string;
  time_zone?: string;
  created_by?: string;
  created_at?: string;
}

export const getCommonColumn = (rowData: ColumnData) => [
  { key: 'Test Event', value: rowData?.payload?.test_event ? 'Yes' : 'No' },
  { key: 'Response Required', value: rowData?.payload?.response_required ? 'Yes' : 'No' }
];

export const getEventColumn = (rowData: ColumnData) => [
  { key: 'VTN Name', value: rowData?.vtn_name },
  { key: 'Duration', value: rowData?.duration },
  { key: 'Time Zone', value: rowData?.time_zone },
  { key: 'Created By', value: rowData?.created_by },
  { key: 'Created At', value: formatDateMoment(rowData?.created_at) }
];

export const getScheduleColumn = (rowData: ColumnData) => [
  { key: 'Target', value: rowData?.payload?.ven_id },
  { key: 'Time Zone', value: rowData?.payload?.time_zone },
  { key: 'Created By', value: rowData?.payload?.created_by }
];

