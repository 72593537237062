import React, { useEffect, useRef } from 'react';
import { Navbar } from './components/navbar/navbar';
import { Sidebar } from './components/sidebar/sidebar';
import { Outlet } from "react-router-dom";
import { AppStyles } from './appStyles';
import { setupAxiosInterceptors } from './Utils/Interceptor';
import { Toast } from 'primereact/toast';
import { applyTheme, theme } from './theme';

function App() {

  const toast = useRef(null);

  useEffect(() => {
    applyTheme(theme);
    setupAxiosInterceptors(toast);
  }, [toast]);

  return (
      <AppStyles.AppComponent data-testid="app-component">
        <Toast ref={toast} position="top-center" />
        <Navbar />
        <AppStyles.MainComponent>
          <Sidebar />
          <AppStyles.ContentPage>
            <Outlet />
          </AppStyles.ContentPage>
        </AppStyles.MainComponent>
      </AppStyles.AppComponent>
  )
}

export default App;
