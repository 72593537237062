import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventDto } from '../types/event';
import { ScheduleLogDto } from '../types/schedule';

interface FetchLogsResponse {
    data: {
        data: {
            data: EventDto[];
            totalCount: number;
            pageLinks: {
                first: string | null;
                prev: string | null;
                next: string | null;
                last: string | null;
            };
        };
    };
    status: number;
}

interface ErrorResponse {
    response?: {
      status: number;
    };
}

type FetchLogService = (page: number, limit: number) => Promise<FetchLogsResponse>;

function useFetchLogs(fetchService: FetchLogService) {
    const [loading, setLoading] = useState(false);
    const [eventLogs, setEventLogs] = useState<EventDto[] | ScheduleLogDto[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageLinks, setPageLinks] = useState<{ first: string | null; prev: string | null; next: string | null; last: string | null; }>({ first: '', prev: null, next: null, last: '' });
    const navigate = useNavigate();

    const fetchLogs = useCallback(async (page: number, limit: number) => {
        setLoading(true);
        try {
            const response = await fetchService(page, limit);
            if (response?.status === 200) {
                const records = response.data.data.data;
                const total = response.data.data.totalCount;
                const pagelink = response.data.data.pageLinks;
                setPageLinks(pagelink);
                if (Array.isArray(records)) {
                    setEventLogs(records);
                    setTotalRecords(total);
                } else {
                    setEventLogs([]);
                    console.error('Expected an array for records, but got:', typeof records);
                }
            } else if (response.status === 403) {
                navigate('/');
                console.error('Error fetching logs: Unauthorized access');
            }
        } catch (error) {
                const err = error as ErrorResponse;
            if (err.response?.status === 403) {
                navigate('/');
            } else {
                console.error('Error fetching logs:', error);
            }
        } finally {
            setLoading(false);
        }
    }, [fetchService, navigate]);

    return { loading, eventLogs, totalRecords, pageLinks, fetchLogs };
}

export default useFetchLogs;
