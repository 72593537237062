import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { useFetchTariffs } from '../../Utils/useFetchTariff';
import { LogsTable } from '../../Utils/fileLogTable';
import { CSVReader } from '../../Utils/fileUpload';


export interface MnvRowData {
    metadata: {
        filenameInBucket: string;
        folder: string;
    };
    generation: string;
}
export const TariffManager: React.FC<{ pageTitle: string; dialogHeader: string }> = ({ pageTitle, dialogHeader }) => {
    const { tariffLogs, isLoading, setLoadTariffs, setPublishedEventId } = useFetchTariffs();
    const [visible, setVisible] = useState(false);
    const toast = useRef<Toast>(null);

    const handleChildEvent = (requestId: string, filename: string) => {
        setLoadTariffs(true);
        setPublishedEventId(requestId + filename);
    };

    const handleDownload = async (rowData: MnvRowData) => {
        const filename = rowData?.metadata?.filenameInBucket;
        try {
            const response = await axios.get(`https://tariff-service-345158162856.us-central1.run.app/v1/tariffs/download?folder=${rowData.metadata?.folder}&filename=${filename}&version=${rowData?.generation}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Download failed', error);
        }
    };

    const hideDialog = () => {
        setVisible(false);
    };

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <LogsTable
                tariffLogs={tariffLogs}
                handleDownload={handleDownload}
                handleChildEvent={handleChildEvent}
                pageTitle={pageTitle}
                setVisible={setVisible}
            />
            <Dialog
                visible={visible}
                className={"w-6"}
                header={dialogHeader}
                modal
                onHide={hideDialog}
                maximizable={window.innerWidth < 768 ? false : true}
                maximized={window.innerWidth < 768 ? true : false}
                dismissableMask
                pt={{ header: {className:'p-2 pl-5' }, headerTitle: {className:'font-bold' } }}>
                <CSVReader
                    onPublishEvent={handleChildEvent}
                    setVisible={setVisible}
                    setLoader={(loading: boolean) => setLoadTariffs(loading)}
                />
            </Dialog>
        </>
    );
};

export const MnVLog: React.FC = () => (
    <TariffManager pageTitle="MnV Data" dialogHeader="Upload Variable Tariff Rate" />
);

export const UploadMnV: React.FC = () => (
    <TariffManager pageTitle="Upload MnV" dialogHeader="Upload MnV Data" />
);
