import React, { FunctionComponent, useState, useEffect, useRef, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Payload, signalPayloadValues } from '../../../types/signalValues';
import { EventScheduleService, UpdateScheduleService } from '../../../service/eventScheduleService';
import { showToast } from '../../../Utils/Utility';
import { EStyles } from '../../events/EventsForm/eventsformStyles';
import { signalNames, vens } from '../../events/EventsForm/eventsFormData';
import { validateSchedulePayload } from '../../../Utils/logTemplates';
import { ScheduleFormValue, ScheduleInitialFormValue } from '../../../types/schedule.formfields';
import { handleCheckBoxChange, handleFormChange } from './useScheduleFormHandlers';
import { RowContainer } from './rowContainer';
import { CheckboxWithLabel } from './checkboxWithLabel';
import { DatePickerInput } from '../../../Utils/datePickerInput';
import { SignalPayloadSelector } from './signalPayloadSelector';
import { ScheduleFormProps } from '../../../types/schedule';

export const ScheduleForm: FunctionComponent<ScheduleFormProps> = ({
  onPublishEvent,
  setVisible,
  setLoader,
  mode,
  rowData,
}) => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [formValue, setFormValue] = useState<ScheduleFormValue>(ScheduleInitialFormValue);
  const [selectedScheduleDate, setSelectedScheduleDate] = useState<Date | null>(null);
  const [selectedBeginDate, setSelectedBeginDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [selectedPayload, setSelectedPayload] = useState<Payload | null>(null);

  useEffect(() => {
    if (mode === 'edit' && rowData?.payload) {
      const startTime = new Date(rowData.payload.start_time);
      setSelectedBeginDate(startTime);

      const endTime = new Date(rowData.payload.end_time);
      setSelectedEndDate(endTime);

      if (rowData.schedule) {
        const schTime = new Date(rowData.schedule);
        setSelectedScheduleDate(schTime);
      }

      const initialPayload = signalPayloadValues.find(
        (payload) => payload.key === Number(rowData.payload.signal_payload)
      );
      if (initialPayload) {
        setSelectedPayload(initialPayload);
        setFormValue((prev) => ({ ...prev, signalPayload: initialPayload.key }));
      }

      setFormValue((prev) => ({
        ...prev,
        beginDate: new Date(rowData.payload.start_time),
        endDate: new Date(rowData.payload.end_time),
        scheduleTime: rowData.schedule ? new Date(rowData.schedule) : null,
        target: rowData.payload.ven_id,
        testEvent: rowData.payload.test_event,
      }));
    }
  }, [mode, rowData]);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      const validationResult = validateSchedulePayload(formValue);
      if (!validationResult.isValid) {
        showToast(toast, 'error', 'Payload Validation Error', validationResult.errorMessage || 'Unknown error', false);
        return;
      }

      setLoader(true);
      let response;
      if (mode === 'create') {
        response = await EventScheduleService(formValue);
      } else if (mode === 'edit') {
        if (rowData?.id) {
          response = await UpdateScheduleService(formValue, rowData.id);
        } else {
          throw new Error('Row data ID is missing');
        }
      }
      if (response?.status === 200 || response?.status === 201) {
        setVisible(false);
        onPublishEvent(mode === 'create' ? 'Scheduled' : 'Updated', response?.data?.data?.request_id);
      } else if (response?.status === 403) {
        navigate('/login');
      } else {
        setVisible(false);
        setLoader(false);
        showToast(toast, 'error', 'Failure', 'Server returned ' + response?.status, false);
      }
    } catch (error) {
      setLoader(false);
      showToast(toast, 'error', 'Failure', `${mode === 'create' ? 'Failed' : 'Unable to update'}: ${error}`, false);
    }
  }

  const clearForm = () => {
    setFormValue(ScheduleInitialFormValue);
    setSelectedPayload(null);
    setSelectedBeginDate(null);
    setSelectedEndDate(null);
    setSelectedScheduleDate(null);
  };
  
  return (
    <EStyles.EventsFormContainer className="p-grid p-fluid">
      <Toast ref={toast} />
      <EStyles.EventsInputContainer>
        <RowContainer
          leftContent={<span>Schedule Date & Time :</span>}
          rightContent={
            <>
              <CheckboxWithLabel
                id="ven"
                label="Virtual End Node"
                checked={true}
                onChange={() => { }}
                dataTestId="virtual-end-node"
                disabled={true}
              />
              <DatePickerInput
                selectedDate={selectedScheduleDate}
                setSelectedDate={(date) => {
                  setSelectedScheduleDate(date);
                  setFormValue((prev) => ({ ...prev, scheduleTime: date as Date | null }));
                }}
                dataTestId="schedule-date"
                timeIntervals={1}
              />
            </>
          }
          className='mt-5'
        />
        <RowContainer
          leftContent={<span>Target :</span>}
          rightContent={
            <>
              <Dropdown
                data-testid="target"
                id="target"
                value={formValue['target']}
                onChange={(e) => handleFormChange(e, formValue, setFormValue)}
                options={vens}
                optionLabel="name"
                optionValue="value"
                appendTo={'self'}
                placeholder="Select Target"
                className="w-full"
              />
            </>
          }
        />
        <RowContainer
          leftContent={<span>Event Start :</span>}
          rightContent={
            <>
              <DatePickerInput
                selectedDate={selectedBeginDate}
                setSelectedDate={(date) => {
                  setSelectedBeginDate(date);
                  setFormValue((prev) => ({ ...prev, beginDate: date as Date | null }));
                }}
                dataTestId="begin-date"
                timeIntervals={30}
              />
            </>
          }
        />
        <RowContainer
          leftContent={<span>Event End :</span>}
          rightContent={
            <>
              <DatePickerInput
                selectedDate={selectedEndDate}
                setSelectedDate={(date) => {
                  setSelectedEndDate(date);
                  setFormValue((prev) => ({ ...prev, endDate: date as Date | null }));
                }}
                dataTestId="end-date"
                timeIntervals={30}
              />
            </>
          }
        />
        <RowContainer
          leftContent={<span>Area :</span>}
          rightContent={
            <>
              <InputText
                data-testid="area"
                id="area"
                value={formValue['area']}
                disabled={true}
                onChange={(e) => handleFormChange(e, formValue, setFormValue)}
              />
            </>
          }
        />
        <RowContainer
          leftContent={<span>Signal Name :</span>}
          rightContent={
            <>
              <Dropdown
                data-testid="signal-name"
                id="signalName"
                value={formValue['signalName']}
                onChange={(e) => handleFormChange(e, formValue, setFormValue)}
                options={signalNames}
                optionLabel="name"
                optionValue="value"
                placeholder="Select a signal name"
                className="w-full"
              />
            </>
          }
        />
        <RowContainer
          leftContent={<span>Signal Payload Value :</span>}
          rightContent={
            <>
              <SignalPayloadSelector
                signalPayloadValues={signalPayloadValues}
                selectedPayload={selectedPayload}
                setSelectedPayload={setSelectedPayload}
                formValue={formValue}
                setFormValue={setFormValue}
              />
              <CheckboxWithLabel
                id="testEvent"
                label="Is a Test Event?"
                checked={formValue['testEvent'] === true}
                onChange={(e) =>
                  handleCheckBoxChange('testEvent', e.checked ?? false, formValue, setFormValue)
                }
                dataTestId="test-event"
              />
            </>
          }
          className='mb-5'
        />
        <EStyles.RowContainer>
          <EStyles.EventsContainerButton onClick={clearForm}>Clear</EStyles.EventsContainerButton>
          <EStyles.EventsContainerButton id="publish" onClick={handleSubmit}>
            {mode === 'create' ? 'Schedule' : 'Update Schedule'}
          </EStyles.EventsContainerButton>
        </EStyles.RowContainer>
      </EStyles.EventsInputContainer>
    </EStyles.EventsFormContainer>
  );
};
