import { TariffRowData } from "../components/tariffs/tariffsLog";

export const bff_url = `${window.__RUNTIME_CONFIG__?.REACT_APP_BFF_URL}/grid-connect/v1`

export const getDownloadUrl = (rowData: TariffRowData) => {
    const { folder, filenameInBucket, generation } = rowData.metadata || {};
    return `https://tariff-service-345158162856.us-central1.run.app/v1/tariffs/download?folder=${folder}&filename=${filenameInBucket}&version=${generation}`;
};

export const errorMessage = {
    title: 'Oops! Something went wrong.',
    message: "We're sorry, but the page you're looking for doesn't exist.",
    buttonText: 'Go to Home'
};

export const loginErrorMessage = {
    title: 'Login Error',
    message: "We're sorry, but there was an error with your login attempt. Please try again.",
    buttonText: 'Go to Login'
};