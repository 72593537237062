import React, { useMemo, useState } from 'react';
import { IconContext } from 'react-icons';
import logo from './../../assets/images/logo.png';
import { NStyles } from './navbarStyles';
import { CgProfile } from 'react-icons/cg';
import { useSessionValidation } from '../../Utils/useSessionValidation';
import { ProfileDialog } from './profileDialog';
import { validateService } from '../../service/authService';

export const Navbar: React.FC<{}> = () => {
    const iconContextValue = useMemo(() => ({ size: '2.5rem', color: "var(--primary-ui)" }), []);
    const { profileData } = useSessionValidation(validateService);
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    const handleProfileIconClick = () => {
        setIsDialogVisible(true);
    };

    const hideDialog = () => {
        setIsDialogVisible(false);
    };
    
    return (
        <>
            <IconContext.Provider value={iconContextValue}>
                <NStyles.NavbarContainer>
                    <NStyles.LogoContainer>
                        <NStyles.Logo src={logo} alt='logoimage.png'></NStyles.Logo>
                        <NStyles.HeaderText>Pacific Gas and Electric Company
                        </NStyles.HeaderText>
                    </NStyles.LogoContainer>
                    {
                        profileData?.username ?
                            <>
                                <NStyles.ProfileIcon onClick={handleProfileIconClick}>
                                    <NStyles.ProfileText>Hello {profileData?.username}! &nbsp;</NStyles.ProfileText>
                                    <CgProfile />
                                </NStyles.ProfileIcon>
                            </>
                            :
                            ''
                    }
                </NStyles.NavbarContainer>
                {profileData && (
                    <ProfileDialog visible={isDialogVisible} onHide={hideDialog} profileData={profileData} />
                )}
            </IconContext.Provider>
        </>
    );
};