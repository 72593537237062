import { DropdownChangeEvent } from 'primereact/dropdown';
import React from 'react';
import DatePicker from 'react-datepicker';
import { ScheduleFormValue } from '../../../types/schedule.formfields';
import { Payload } from '../../../types/signalValues';

export const handleFormChange = (
  e: React.ChangeEvent<HTMLInputElement> | DropdownChangeEvent,
  formValue: ScheduleFormValue,
  setFormValue: React.Dispatch<React.SetStateAction<ScheduleFormValue>>
) => {
  const target = e.target as HTMLInputElement | { id: string; value: any };
  const { id, value } = target;
  const payload = { ...formValue };

  if (id in payload) {
    (payload[id as keyof ScheduleFormValue] as unknown) = value;
  }

  setFormValue(payload);
};
export const handleCheckBoxChange = (
  id: keyof ScheduleFormValue,
  checked: boolean,
  formValue: ScheduleFormValue,
  setFormValue: React.Dispatch<React.SetStateAction<ScheduleFormValue>>
) => {
  const payload: ScheduleFormValue = { ...formValue };
  if (id === 'testEvent') {
    payload[id] = checked;
  }
  setFormValue(payload);
};

export const handleRadioBoxChange = (
  value: Payload,
  formValue: ScheduleFormValue,
  setFormValue: React.Dispatch<React.SetStateAction<ScheduleFormValue>>,
  setSelectedPayload: React.Dispatch<React.SetStateAction<Payload | null>>
) => {
  const payload = { ...formValue };
  payload['signalPayload'] = value.key;
  setFormValue(payload);
  setSelectedPayload(value);
};

export const handleDateTextBoxClick = (
  pickerRef: React.RefObject<DatePicker>
) => {
  if (pickerRef.current) {
    pickerRef.current.setOpen(true);
  }
};
