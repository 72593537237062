import React, { useState, useEffect, useRef, FunctionComponent } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import { useResponsiveTable } from '../../../Utils/useResponsiveTable';
import { EventLogService } from '../../../service/eventLogService';
import { createPaginatorTemplate } from '../../../Utils/paginationTemplate';
import { ClipLoader } from 'react-spinners';
import { LStyles } from './eventlogsStyles';
import collapseIcon from '../../../assets/images/collapseIcon.svg';
import generateIcon2 from '../../../assets/images/generateIcon2.svg';
import { responseCodeTemplateForEvent, responseCreatedDateTemplateForEvent,responseEndDateTemplateForEvent, responsePayloadTemplateForEvent, responseStartDateTemplateForEvent } from '../../../Utils/logTemplates';
import { RequestIdTemplate } from '../EventsIdTemplate/events_id_template';
import { BaseEvent, EventDto } from '../../../types/event';
import ResponseObjectTemplate from '../EventsOverlay/events_overlay';
import { EventDeliveryService } from '../../../service/eventDeliveryService';
import { sanitizeRequestId, showToast } from '../../../Utils/Utility';
import { HttpStatusCode } from 'axios';
import { Dialog } from 'primereact/dialog';
import { EventsForm } from '../EventsForm/events_form';
import useFetchLogs from '../../../Utils/useFetchLogs';

interface DataTableStateEvent {
    first: number;
    rows: number;
    page?: number;
}

interface RowData {
    request_id: string;
    status: string;
    id: string;
    payload: BaseEvent;
  }

export const EventsLog: FunctionComponent = () => {
    const [visible, setVisible] = useState(false);
    const [loadEvents, setLoadEvents] = useState(true);
    const [expandedRows, setExpandedRows] = useState<EventDto[]>([]);
    const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>({});
    const [rowData, setRowData] = useState<{ [key: string]: EventDto | undefined }>({});
    const toast = useRef<Toast>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [publishedEventId, setPublishedEventId] = useState<string>();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [page, setPage] = useState(1);
    const { scrollWidth, tableSize , scrollHeight} = useResponsiveTable();

    const { loading, eventLogs, totalRecords, pageLinks: rawPageLinks, fetchLogs } = useFetchLogs(EventLogService);
    const pageLinks = {
        ...rawPageLinks,
        prev: rawPageLinks?.prev !== null,
        next: rawPageLinks?.next !== null,
        first: rawPageLinks?.first !== null,
        last: rawPageLinks?.last !== null,
    };

    const expandTemplate = (data: EventDto) => {
        const isLoading = loadingRows[data.id];
        return (
            <LStyles.TemplateContainer>
                <DataTable value={data?.delivery_logs} stripedRows emptyMessage="No records found." loading={isLoading} size={tableSize} className="border-none sm:text-xs md:text-xs lg:text-sm xl:text-md">
                    <Column field="consumer_name" header="Name" sortable
                        headerStyle={{ backgroundColor: 'lightblue' }} />
                    <Column field="response_code" header="Response Code" sortable
                        headerStyle={{ backgroundColor: 'lightblue' }} body={responseCodeTemplateForEvent} />
                    <Column field="response_object" header="Payload Sent" body={ResponseObjectTemplate}
                        headerStyle={{ backgroundColor: 'lightblue' }} />
                </DataTable>
            </LStyles.TemplateContainer>
        );
    };


    useEffect(() => {
        if (loadEvents) {
            fetchLogs(page, rows);
           setLoadEvents(false);
           setIsLoading(false)
        }
        if (publishedEventId) {
            showToast(toast, "success", "Event Published!", `Id : ${publishedEventId}`, true);
        }
    }, [loadEvents, fetchLogs, isLoading, publishedEventId]);

    useEffect(() => {
        fetchLogs(page, rows);
    }, [page, rows, fetchLogs]);

    const onPageChange = (event: DataTableStateEvent): void => {
        setFirst(event.first);
        setRows(event.rows);
        setPage((event.page ?? 0) + 1);
    };

    const paginationTemplate = createPaginatorTemplate(page, rows, totalRecords, pageLinks, setPage, setRows);

    const renderRequestIdTemplate = (rowData: RowData) => {
        return <RequestIdTemplate rowData={rowData} type="event" />;
    };

    const onRowCollapse = () => {
        setExpandedRows([]);
    };

    const fetchDeliveryLog = async (eventDto: string): Promise<EventDto | undefined> => {
        setLoadingRows(prevState => ({ ...prevState, [eventDto]: true }));

        try {
            const response = await EventDeliveryService(eventDto);
            if (response?.status === 200) {
                eventLogs.forEach((item) => {
                    if (item.id === eventDto && 'delivery_logs' in item) {
                        (item as EventDto).delivery_logs = response?.data?.data;
                    }
                });
                return response?.data?.data;
            } else if (response.status === HttpStatusCode.Forbidden) {
                showToast(toast, "error", "Oops!", "Error Fetching Delivery Log", false);
                return undefined;
            } else {
                console.log("Error Fetching Delivery Log " + response.data.message);
            }
        } catch (error) {
            showToast(toast, "error", "Oops!", "Error Fetching Delivery Log", false);
        } finally {
            setLoadingRows(prevState => ({ ...prevState, [eventDto]: false }));
        }
    };


    const onRowToggle = async (event: { data: EventDto[] }) => {
        const expanded = event?.data;
        setExpandedRows(expanded);

        if (expanded?.length > 0) {
            const expandedRowId = expanded[expanded?.length - 1]?.id;
            if (!rowData[expandedRowId]) {
                try {
                    const apiData: EventDto | undefined = await fetchDeliveryLog(expandedRowId);
                    if (apiData) {
                        setRowData((prevData) => ({ ...prevData, [expandedRowId]: apiData }));
                    }
                } catch (error) {
                    console.error("Error fetching delivery logs:", error);
                }
            }
        }
    };
    
    const hideDialog = () => {
        setVisible(false);
    };

    const handleChildEvent = (requestId: string) => {
        setLoadEvents(true);
        setPublishedEventId(requestId)
    };
    
    return (
        <>
            {isLoading ? (
                <div className="loader">
                    <ClipLoader color="var(--primary-ui)" loading={isLoading} size={100} aria-label="Publishing" data-testid="loader" className="loader-img" />
                </div>
            ) : (
                <LStyles.EventsLogContainer>
                    <LStyles.HeaderContainer>
                        <Toast ref={toast} position="top-right" />
                        <LStyles.PageHeader>PUBLISHED EVENTS</LStyles.PageHeader>
                        <LStyles.ButtonContainer className="m-2">
                            <LStyles.CustomButton onClick={() => onRowCollapse()}>
                                <img src={collapseIcon} alt="generateIcon" className="mr-2" />
                                Collapse All
                            </LStyles.CustomButton>
                            &nbsp;
                            <LStyles.CustomButton onClick={() => setVisible(true)} data-testid="generate-btn">
                                <img src={generateIcon2} alt="generateIcon" className="mr-2" />
                                Generate Event
                            </LStyles.CustomButton>
                        </LStyles.ButtonContainer>
                    </LStyles.HeaderContainer>
                    <LStyles.DataTableContainer>
                        <LStyles.TableContainer>
                            <ScrollPanel style={{ width: scrollWidth, height: scrollHeight}}>
                                <DataTable
                                    value={eventLogs}
                                    loading={loading}
                                    paginator
                                    paginatorTemplate={paginationTemplate}
                                    rows={rows}
                                    totalRecords={totalRecords}
                                    size={tableSize}
                                    expandedRows={expandedRows} onRowToggle={onRowToggle}
                                    rowExpansionTemplate={expandTemplate}
                                    first={first}
                                    onPage={onPageChange}
                                    filterDisplay="menu"
                                    sortField="rowId"
                                    sortOrder={-1}
                                    rowsPerPageOptions={[5, 10, 20, 120]}
                                    emptyMessage="No events found"
                                    className={`dataTableClass datatable-bg sm:text-xs md:text-xs lg:text-sm xl:text-md fadein animation-duration-100`}
                                    pt={{paginator:{root:{className:'sm:p-0'}}}}
                                    rowClassName={(rowData) => sanitizeRequestId(rowData?.request_id)}
                                >
                                    <Column expander />
                                    <Column key="created_at" sortable field="created_at" header="Created At"
                                         body={responseCreatedDateTemplateForEvent} />
                                    <Column key="signal_name" sortable field="signal_name" header="Signal Name"
                                         />
                                    <Column key="ven_id" sortable field="ven_id" header="Target"
                                         />
                                    <Column key="start_time" sortable field="payload?.start_time" header="Event Start"
                                         body={responseStartDateTemplateForEvent} />
                                    <Column key="end_time" sortable field="end_time" header="Event End"
                                        body={responseEndDateTemplateForEvent} />
                                    <Column key="signal_payload" sortable field="signal_payload" header="Signal Payload"
                                         body={responsePayloadTemplateForEvent}/>
                                    <Column key="area_code" sortable field="area_code" header="Area"
                                        />
                                    <Column
                                        key="request_id"
                                        field="request_id"
                                        headerStyle={{ backgroundColor: "lightgray" }}
                                        body={renderRequestIdTemplate}
                                    />
                                </DataTable>
                            </ScrollPanel>
                        </LStyles.TableContainer>
                    </LStyles.DataTableContainer>
                    <Dialog
                        visible={visible}
                        className={"w-6"}
                        header="Generate Event"
                        modal
                        onHide={hideDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask
                        pt={{ header: {className:'p-2 pl-5' }, headerTitle: {className:'font-bold' } }}>
                        <EventsForm
                            onPublishEvent={handleChildEvent}
                            setVisible={setVisible}
                            setLoader={setIsLoading}
                        />
                    </Dialog>
                </LStyles.EventsLogContainer>
            )}
        </>
    );
};