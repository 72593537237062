import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useFetchTariffs } from "../../Utils/useFetchTariff";
import axios from "axios";
import { LogsTable } from "../../Utils/fileLogTable";
import {CSVReader} from "../../Utils/fileUpload";
import { showToast } from "../../Utils/Utility";
import { getDownloadUrl } from "../../const/app.constants";

type EventType = 'uploaded' | 'failed' | 'Cancelled';

interface ConfigValue {
    toastType: string;
    title: string;
    detail: string;
}

export interface TariffRowData {
    metadata: {
        folder: string;
        filenameInBucket: string;
        generation: string;
    };
}

export const TariffsLog: React.FC = () => {
    const { tariffLogs, setLoadTariffs,publishedEventId ,setPublishedEventId,setIsLoading } = useFetchTariffs();
    const [visible, setVisible] = useState(false);
    const toast = useRef<Toast>(null);

    const handleChildEvent = (requestId: string, filename: string) => {
        setLoadTariffs(true);
        setPublishedEventId(requestId + filename);
    };

   useEffect(() => {
      if (!publishedEventId) return;
  
      const eventTypes: EventType[] = ['uploaded', 'failed', 'Cancelled'];
  
      const eventType = eventTypes.find((type): type is EventType => publishedEventId.includes(type));
  
      if (eventType) {
          const message = publishedEventId.split(eventType)[1];
  
          const config: Record<EventType, ConfigValue> = {
              uploaded: {
                  toastType: 'success',
                  title: 'Complete!',
                  detail: `${message} has been uploaded successfully!`,
              },
              failed: {
                  toastType: 'error',
                  title: `Failed to Upload: ${message}`,
                  detail: 'File already exists. Please change the file name.',
              },
              Cancelled: {
                  toastType: 'warn',
                  title: 'Complete!',
                  detail: `${message} has been deleted successfully!`,
              },
          };
  
          const { toastType, title, detail } = config[eventType];
  
          showToast(toast, toastType as 'success' | 'error' | 'warn', title, detail, false);
          setPublishedEventId('');
      }
  }, [publishedEventId]);

 

    const handleDownload = async (rowData: TariffRowData) => {
        const filename = rowData?.metadata?.filenameInBucket;
        try {
            const response = await axios.get(getDownloadUrl(rowData), {
                responseType: 'blob'
            });
            showToast(toast,"success",filename + " downloaded successfully","",false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Download failed', error);
        }
    };

    const hideDialog = () => {
        setVisible(false);
    };

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <LogsTable tariffLogs={tariffLogs} handleDownload={handleDownload} handleChildEvent={handleChildEvent} pageTitle="TARIFFS" setVisible={setVisible} />
            <Dialog
                visible={visible}
                className={"w-7"}
                header="Upload Variable Tariff Rate"
                modal
                onHide={hideDialog}
                maximizable={window.innerWidth < 768 ? false : true}
                maximized={window.innerWidth < 768 ? true : false}
                dismissableMask
                pt={{ header: {className:'p-2 pl-5' }, headerTitle: {className:'font-bold' } }}>
                <CSVReader
                    onPublishEvent={handleChildEvent}
                    setVisible={setVisible}
                    setLoader={setIsLoading}
                />
            </Dialog>
        </>
    );
};