import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorStyles } from './errorStyles';
import { loginErrorMessage } from '../../const/app.constants';

const LoginError = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/login');
    };

    return (
        <ErrorStyles.Container>
            <ErrorStyles.Title>{loginErrorMessage.title}</ErrorStyles.Title>
            <ErrorStyles.Message>{loginErrorMessage.message}</ErrorStyles.Message>
            <ErrorStyles.HomeButton onClick={handleButtonClick}>{loginErrorMessage.buttonText}</ErrorStyles.HomeButton>
        </ErrorStyles.Container>
    );
};

export default LoginError;