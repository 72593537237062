import React, { useState, useRef, FunctionComponent } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { EventPublishService } from "../../../service/eventPublishService";
import { Toast } from "primereact/toast";
import { vens, signalNames } from "./eventsFormData";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { EStyles } from "./eventsformStyles";
import { showToast } from "../../../Utils/Utility";
import { signalPayloadValues } from "../../../types/signalValues";
import { validateEventPayload } from "../../../Utils/logTemplates";
import { useEventFormHandlers } from "./useEventFormHandlers";
import { EventInitialFormValue } from "../../../types/event.formfields";
import { RowContainer } from "../../schedules/SchedulesForm/rowContainer";
import { CheckboxWithLabel } from "../../schedules/SchedulesForm/checkboxWithLabel";
import { DatePickerInput } from "../../../Utils/datePickerInput";
import { SignalPayloadSelector } from "../../schedules/SchedulesForm/signalPayloadSelector";

type EventFormProps = {
    onPublishEvent: (data: string) => void;
    setVisible: (data: boolean) => void;
    setLoader: (data: boolean) => void;
};

export const EventsForm: FunctionComponent<EventFormProps> = ({ onPublishEvent, setVisible, setLoader }) => {

    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    const [selectedBeginDate, setSelectedBeginDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

    const {
        formValue,
        setFormValue,
        handleFormChange,
        handleCheckBoxChange,
        selectedPayload,
        setSelectedPayload
    } = useEventFormHandlers(EventInitialFormValue);

    const sendEvent: React.MouseEventHandler<HTMLButtonElement> = async () => {
        try {
            const validationResult = validateEventPayload(formValue);
            if (!validationResult.isValid) {
                showToast(
                    toast,
                    'error',
                    'Payload Validation Error',
                    validationResult.errorMessage || 'Unknown error',
                    false
                );
                return;
            }
            setLoader(true);
            let response = await EventPublishService(formValue);
            if (response?.status === 200 || response?.status === 201) {
                setVisible(false);
                onPublishEvent(response?.data?.data?.request_id);
            } else if (response?.status === 403) {
                navigate('/login');
            } else {
                setLoader(false);
                showToast(toast, 'error', 'Failure', 'Server returned ' + response?.status, false);
            }
        } catch (error) {
            setLoader(false);
            showToast(toast, 'error', 'Failure', 'Unable to publish event', false);
        }
    };

    const clearForm = () => {
        setFormValue(EventInitialFormValue);
        setSelectedPayload(null)
        setSelectedBeginDate(null)
        setSelectedEndDate(null)
    };

    return (
        <EStyles.EventsFormContainer className="p-grid p-fluid">
            <Toast ref={toast} />
            <EStyles.EventsInputContainer>
                <RowContainer
                    leftContent={<span>Target :</span>}
                    rightContent={
                        <>
                            <CheckboxWithLabel
                                id="ven"
                                label="Virtual End Node"
                                checked={true}
                                onChange={() => { }}
                                dataTestId="virtual-end-node"
                                disabled={true}
                            />
                            <Dropdown
                                data-testid="target"
                                id="target"
                                value={formValue['target']}
                                onChange={(e) => handleFormChange(e)}
                                options={vens}
                                optionLabel="name"
                                optionValue="value"
                                appendTo={'self'}
                                placeholder="Select Target"
                                className="w-full"
                            />
                        </>
                    }
                />
                <RowContainer
                    leftContent={<span>Event Start :</span>}
                    rightContent={
                        <>
                            <DatePickerInput
                                selectedDate={selectedBeginDate}
                                setSelectedDate={(date) => {
                                    setSelectedBeginDate(date);
                                    setFormValue((prev) => ({ ...prev, beginDate: date as Date | null }));
                                }}
                                dataTestId="begin-date"
                            />
                        </>
                    }
                />
                <RowContainer
                    leftContent={<span>Event End :</span>}
                    rightContent={
                        <>
                            <DatePickerInput
                                selectedDate={selectedEndDate}
                                setSelectedDate={(date) => {
                                    setSelectedEndDate(date);
                                    setFormValue((prev) => ({ ...prev, endDate: date as Date | null }));
                                }}
                                dataTestId="end-date"
                            />
                        </>
                    }
                />
                <RowContainer
                    leftContent={<span>Area :</span>}
                    rightContent={
                        <>
                            <InputText data-testid="area" id="area" value={formValue['area']} disabled={true}
                                onChange={e => handleFormChange(e)} />
                        </>
                    }
                />

                <RowContainer
                    leftContent={<span>Signal Name :</span>}
                    rightContent={
                        <>
                            <Dropdown data-testid="signal-name" id="signalName" value={formValue["signalName"]}
                                onChange={(e) => handleFormChange(e)}
                                options={signalNames} optionLabel="name" optionValue="value"
                                placeholder="Select a signal name" className="w-full" />
                        </>
                    }
                />
                <RowContainer
                    leftContent={<span>Signal Payload Value :</span>}
                    rightContent={
                        <>
                            <SignalPayloadSelector
                                signalPayloadValues={signalPayloadValues}
                                selectedPayload={selectedPayload}
                                setSelectedPayload={setSelectedPayload}
                                formValue={formValue}
                                setFormValue={setFormValue}
                            />
                            <CheckboxWithLabel
                                id="testEvent"
                                label="Is a Test Event?"
                                checked={formValue['testEvent'] === true}
                                onChange={(e) =>
                                    handleCheckBoxChange('testEvent', e.checked ?? false)
                                }
                                dataTestId="test-event"
                            />
                        </>
                    }
                    className="mb-5"
                />
                <EStyles.RowContainer>
                </EStyles.RowContainer>
                <EStyles.RowContainer>
                    <EStyles.EventsContainerButton onClick={clearForm}>Clear</EStyles.EventsContainerButton>
                    <EStyles.EventsContainerButton id={"publish"} onClick={sendEvent}>Publish</EStyles.EventsContainerButton>
                </EStyles.RowContainer>
            </EStyles.EventsInputContainer>
        </EStyles.EventsFormContainer>)
}


