import React from 'react';
import { EStyles } from '../../events/EventsForm/eventsformStyles';

type RowContainerProps = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  className?: string;
};

export const RowContainer: React.FC<RowContainerProps> = ({
  leftContent,
  rightContent,
  className,
}) => {
  return (
    <EStyles.RowContainer>
      <EStyles.LeftColumnContainer className={className}>{leftContent}</EStyles.LeftColumnContainer>
      <EStyles.RightColumnContainer>{rightContent}</EStyles.RightColumnContainer>
    </EStyles.RowContainer>
  );
};