import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { showToast } from '../../Utils/Utility';

type EventType = 'uploaded' | 'failed' | 'Cancelled';

interface ConfigValue {
    toastType: string;
    title: string;
    detail: string;
}

export const useEventHandling = (publishedEventId: string, setPublishedEventId: (id: string) => void) => {
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (!publishedEventId) return;

    const eventTypes: EventType[] = ['uploaded', 'failed', 'Cancelled'];
    const eventType = eventTypes.find((type): type is EventType => publishedEventId.includes(type));

    if (eventType) {
      const message = publishedEventId.split(eventType)[1];

      const config: Record<EventType, ConfigValue> = {
        uploaded: {
          toastType: 'success',
          title: 'Complete!',
          detail: `${message} has been uploaded successfully!`,
        },
        failed: {
          toastType: 'error',
          title: `Failed to Upload: ${message}`,
          detail: 'File already exists. Please change the file name.',
        },
        Cancelled: {
          toastType: 'warn',
          title: 'Complete!',
          detail: `${message} has been deleted successfully!`,
        },
      };

      const { toastType, title, detail } = config[eventType];

      showToast(toast, toastType as 'success' | 'error' | 'warn', title, detail, false);
      setPublishedEventId('');
    }
  }, [publishedEventId, setPublishedEventId]);

  return { toast };
};
