import React, { useRef, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import DatePicker from 'react-datepicker';
import { isFutureDate } from '../components/events/EventsForm/useEventFormHandlers';
import { EStyles } from '../components/events/EventsForm/eventsformStyles';

type DatePickerInputProps = {
  selectedDate: Date | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  minDate?: Date;
  timeIntervals?: number;
  dataTestId?: string;
};

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
  selectedDate,
  setSelectedDate,
  minDate = new Date(),
  timeIntervals,
  dataTestId,
}) => {
  const datePickerRef = useRef<DatePicker>(null);
  const [selectedDateTemp, setSelectedDateTemp] = useState<Date | null>(selectedDate);

  const handleDateChange = () => {
    setSelectedDate(selectedDateTemp);
  };

  const handleDateTextBoxClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  useEffect(() => {
    setSelectedDateTemp(selectedDate);
  }, [selectedDate]);

  return (
    <>
        <InputText
          data-testid={dataTestId}
          value={
            selectedDate
              ? selectedDate
                  .toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                  .replace(',', '')
              : ''
          }
          onClick={handleDateTextBoxClick}
        />
        <EStyles.StyledDatePicker
          ref={datePickerRef}
          selected={selectedDateTemp}
          onChange={(date: Date) => {
            setSelectedDateTemp(date);
          }}
          onCalendarClose={handleDateChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={timeIntervals}
          timeCaption="time"
          minDate={minDate}
          minTime={
            new Date(
              selectedDateTemp && isFutureDate(selectedDateTemp)
                ? new Date().setHours(0, 0)
                : new Date()
            )
          }
          maxTime={new Date(new Date().setHours(23, 59))}
          dateFormat="MM/dd/yyyy HH:mm"
        />
</>
  );
};
