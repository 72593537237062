import moment from "moment";
import { ToastMessage } from "primereact/toast";

export interface ToastRef {
    current: {
        show: (message: ToastMessage) => void;
    } | null;
}

export const showToast = (toastRef:ToastRef,severity: ToastMessage["severity"],summary: string, detail:string, sticky:boolean) => {
    toastRef.current?.show({severity:severity,summary:summary,detail:detail,sticky:sticky})
}

export const formatDateMoment = (date: string | undefined) => {
    return date ? moment(date).format('MM/DD/YYYY, HH:mm') : 'NULL';
};

export const formatDate = (inputDate: string) => {
    const date = new Date(inputDate)
    if (isNaN(date.getTime())) {
        return null;
    }
    const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        // second: "2-digit",
        hour12: false,
        timeZone: "UTC",
    }).format(date);
    return formattedDate;
};


export function sanitizeRequestId(requestId:string) {
    return String(requestId).replace(/[^a-zA-Z0-9\-_]/g, '_');
}