import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload, FileUploadHandlerEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { showToast } from './Utility';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IoMdClose } from "react-icons/io";
import { UploadFileService } from '../service/uploadService';
import { Message } from 'primereact/message';
import { EStyles } from '../components/events/EventsForm/eventsformStyles';
import { checkCsvFormat } from './csvValidation';

type CsvReaderProps = {
  onPublishEvent?: (status: string, fileName: string) => void;
  setVisible?: (visible: boolean) => void;
  setLoader?: (loading: boolean) => void;
};

export const CSVReader: FunctionComponent<CsvReaderProps> = ({
  onPublishEvent = () => {},
  setVisible = () => {},
  setLoader = () => {},
}) => {

  const toast = useRef<Toast>(null);
  const [formValue, setFormvalue] = useState<string | null>('')
  const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});
  const [fileValidations, setFileValidations] = useState<{ [key: string]: boolean }>({});
  const [hasInvalidFiles, setHasInvalidFiles] = useState(false);
  const [totalSize, setTotalSize] = useState(0);

  const uploadFile = async (file: File, fileExtension: string, fileName: string, formValue: string) => {
    if (fileExtension === 'csv') {
      try {
        await UploadFileService(file, fileExtension, fileName || file.name, formValue);
        setVisible(false);
        onPublishEvent('uploaded', fileName || file.name);
      } catch (error) {
        setVisible(false);
        onPublishEvent('failed', fileName || file.name);
        setLoader(false);
      }
    }  else {
      showToast(toast, 'error', 'Unsupported file type', '', false);
    }
  };
  
  const onUpload = async (event: FileUploadHandlerEvent) => {
    const files = event.files;
    setLoader(true);
    files.forEach((file) => {
      const key = getFileKey(file);   
      const fileExtension: string = file.name.split('.')[1];
      const fileName = fileNames[key] || file.name.split('.')[0];      
      uploadFile(file, fileExtension, fileName, formValue || '');
    });
  };

  const onTemplateRemove = (file: File, callback: Function) => {
    setTotalSize(totalSize - file.size);
    callback();
    setFormvalue(null)
    setFileValidations({})
  }

  const handleFormChange: Function = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormvalue(e.target.value);
  }

  const getFileKey = (file: File) => `${file.name}-${file.lastModified}`;

  useEffect(() => {
    const anyInvalid = Object.values(fileValidations).includes(false);
    setHasInvalidFiles(anyInvalid);
  }, [fileValidations]);

  const onSelect = (event: { files: File[] }) => {
    const selectedFiles = event.files; 
    setFileValidations({});
    selectedFiles.forEach((file: File) => {
      validateFile(file);
    });
  };

  const onClear = () => {
    setFileValidations({});
  };

  const validateFile = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const key = getFileKey(file);

    const reader = new FileReader();

    reader.onload = async (e) => {
      const fileContents = e.target?.result;

      const isValid = await validateFileContents(fileContents ?? null, fileExtension || '');

      if (!isValid) {
        showToast(toast, "error", "File Validation Error", "Please check the file", false)
      }

      setFileValidations((prevValidations) => ({
        ...prevValidations,
        [key]: isValid,
      }));
    };

    if (fileExtension === 'csv') {
      reader.readAsText(file);
    } else {
      setFileValidations((prevValidations) => ({
        ...prevValidations,
        [key]: false,
      }));
    }
  };

  const validateFileContents = async (fileContents: string | ArrayBuffer | null, fileExtension: string): Promise<boolean> => {
    if (fileExtension === 'csv') {
      const csvData = fileContents as string;
      return checkCsvFormat(csvData);
    } else {
      return false;
    }
  };

const onRemove = (event: { file: File }) => {
  const removedFile = event.file;
  const key = getFileKey(removedFile);

  setFileValidations((prevValidations) => {
    const { [key]: _, ...rest } = prevValidations;
    return rest;
  });
};


const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
  const file = inFile as File;

  if (!file || !file.name) {
    return null;
  }

  const key = getFileKey(file);
  const isValid = fileValidations[key];
  const fileName =
    fileNames[key] !== undefined
      ? fileNames[key]
      : file.name.split('.')[0];
  
  const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileNames((prevFileNames) => ({
      ...prevFileNames,
      [key]: e.target.value,
    }));
  };  
  return (<>
  
    <div className="card grid justify-content-evenly m-0">
      <Toast ref={toast} className='text-left'/>
      <div className="col-5">
        <span className="flex flex-column text-left p-ml-3">
          <div className="text-left">File Name :</div>
         <InputText
              id={`area-${key}`}
              value={fileName}
              data-testid="file-name"
              onChange={handleFileNameChange}
              className="col-12 p-2"
            />
          <small>{new Date().toLocaleDateString()}</small>
        </span>
      </div>
      <div className="col-4 flex flex-column">
        <div className="text-left">Remarks :</div>
        <InputText
          data-testid="remarks"
          id="remarks"
          value={formValue}
          onChange={(e) => handleFormChange(e)}
          className="col-12 p-2"
          placeholder='Remarks (Optional)'
        />
      </div>
      <div className="flex align-items-center">
        <Button
          icon={<IoMdClose />} rounded outlined severity="danger" aria-label="Cancel"
          onClick={() => onTemplateRemove(file, props.onRemove)} className='h-2rem'
        />
      </div>
      {isValid === false && (
        <Message severity="error" text="Invalid file contents, Please check the file." />
      )}
      </div>
      </>
  );
};
  return (
    <>
    <EStyles.EventsFormContainer className='mx-3'>
      <FileUpload
        accept=".csv"
        maxFileSize={3145728}
        customUpload
        onSelect={onSelect}
        onRemove={onRemove}
        uploadHandler={onUpload}
        onClear={onClear}
        progressBarTemplate
        data-testid="file-input"
        cancelOptions={{ className: 'hidden' }}
        uploadOptions={{ className: 'ford-bg-primary' }}
        itemTemplate={itemTemplate}
        emptyTemplate={<div className="p-d-flex p-ai-center p-jc-center">Drag and drop the file to upload</div>}
        pt={{
          chooseButton: { className: 'ford-bg-primary'},
          buttonbar: { className: 'justify-content-end' },
          badge: { root: { className: 'hidden' } },
          uploadButton:{root:{className:`${hasInvalidFiles ? 'p-disabled': ''}`}}
        }}
      />
      </EStyles.EventsFormContainer>
      </>
  );
};