import React, { useCallback } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScrollPanel } from "primereact/scrollpanel";
import moment from "moment";
import { usePagination } from "./usePagination";
import { useResponsiveTable } from "./useResponsiveTable";
import { LStyles } from "../components/events/EventsLog/eventlogsStyles";
import uploadIcon from '../assets/images/uploadIcon.svg'
import { ConfirmPopup } from "primereact/confirmpopup";
import { useFetchTariffs } from "./useFetchTariff";
import { ActionButtons } from "../components/tariffs/tariffButtons";
import { sanitizeRequestId } from "./Utility";

interface LogsTableProps {
    tariffLogs: TariffLog[];
    handleDownload: (rowData: any) => void;
    handleChildEvent: (requestId: string, filename: string) => void;
    pageTitle: string;
    setVisible: (visible: boolean) => void;
}

interface Metadata {
    filename: string;
    remarks: string;
    folder: string;
    filenameInBucket: string;
}

interface TariffLog {
    created_at: string;
    metadata: Metadata;
    size: number;
    generation: string;
}

interface ActionButtonsProps {
    rowData: TariffLog;
    handleDownload: (rowData: any) => void;
    setIsLoading: (loading: boolean) => void;
    handleChildEvent: (requestId: string, filename: string) => void;
    pageTitle: string;
}

export const ActionButtonsWrapper : React.FC<ActionButtonsProps> = ({ rowData, handleDownload, setIsLoading, handleChildEvent, pageTitle }) => (
    <ActionButtons
        rowData={rowData}
        handleDownload={handleDownload}
        setIsLoading={setIsLoading}
        handleChildEvent={handleChildEvent}
        pageTitle={pageTitle}
    />
);

export const LogsTable: React.FC<LogsTableProps> = ({ tariffLogs, handleDownload, handleChildEvent,pageTitle,setVisible }) => {

   
    const { scrollHeight, scrollWidth, tableSize, paginatorTemplate, dataTableClass } = useResponsiveTable();
    const {isLoading , setIsLoading } = useFetchTariffs();
    const { first, rows, onPageChange } = usePagination();

    const responseStartDateTemplate = (rowData: TariffLog) => {
        return rowData?.created_at ? moment(rowData?.created_at).format('MM/DD/YYYY, HH:mm') : 'NULL';
    };

    const responseFileName = (rowData: TariffLog) => {
        return rowData.metadata?.filename ? rowData.metadata?.filename.split('.')[0] : 'NULL';
    };

    const responseFileType = (rowData: TariffLog) => {
        return rowData.metadata?.filename ? rowData.metadata?.filename.split('.')[1] : 'NULL';
    };

    const responseFileSize = (rowData: TariffLog) => {
        const mb = rowData.size / 1024;
        return mb.toFixed(2) + ' Kb';
    };

    const responseRemarks = (rowData: TariffLog) => {
        return rowData?.metadata?.remarks === "" ? 'null' : rowData?.metadata?.remarks;
    };

    const renderActionButtons = useCallback(
        (rowData: TariffLog) => (
            <ActionButtonsWrapper
            rowData={rowData}
            handleDownload={handleDownload}
            setIsLoading={setIsLoading}
            handleChildEvent={handleChildEvent}
            pageTitle={pageTitle}
          />
        ),
        [handleDownload, setIsLoading, handleChildEvent, pageTitle]
      );

    return (
        <>
        <ConfirmPopup/>
                <LStyles.EventsLogContainer>
                     <LStyles.HeaderContainer>
                        <LStyles.PageHeader className="my-2">{pageTitle}</LStyles.PageHeader>
                        {pageTitle === "TARIFFS" || pageTitle === "Upload MnV" ? 
                        <LStyles.ButtonContainer className="m-2">
                            <LStyles.CustomButton onClick={() => setVisible(true)} data-testid="generate-btn" className="mr-2"><img src={uploadIcon} alt="generateIcon" className="mr-2" />Upload {pageTitle === "TARIFFS" ? 'Tariff': 'MnV'}</LStyles.CustomButton>
                        </LStyles.ButtonContainer>
                        :''}
                    </LStyles.HeaderContainer>
                    <LStyles.DataTableContainer>
                        <LStyles.TableContainer>
                            <ScrollPanel style={{ width: scrollWidth }}>
                                <DataTable paginatorTemplate={paginatorTemplate} value={tariffLogs} size={tableSize} stripedRows scrollable scrollHeight={scrollHeight}
                                    onPage={onPageChange} loading={isLoading}
                                    paginator rows={rows} first={first}
                                    filterDisplay={"menu"}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    rowClassName={(rowData) => sanitizeRequestId(rowData.generation)}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events" emptyMessage="No tariffs found." className={`${dataTableClass} datatable-bg sm:text-xs md:text-xs lg:text-sm xl:text-md fadein animation-duration-100`}>
                                    <Column key="filename" sortable header="File Name"
                                         body={responseFileName} />
                                    <Column key="created_at" sortable header="File Type" body={responseFileType}
                                         />
                                    <Column key="created_at" sortable header="Upload Date & Time"
                                         body={responseStartDateTemplate} />
                                    <Column key="size" sortable field="size" header="File Size"
                                         body={responseFileSize} />
                                    <Column key="remarks" sortable header="Remarks"
                                         body={responseRemarks} />
                                    <Column
                                        field="generation"
                                        
                                        header="Actions"
                                        body={renderActionButtons}
                                    />
                                </DataTable>
                            </ScrollPanel>
                        </LStyles.TableContainer>
                    </LStyles.DataTableContainer>
                </LStyles.EventsLogContainer>
        </>
    );
};