import styled from "styled-components";
import DatePicker from 'react-datepicker';
import {InputText} from 'primereact/inputtext';

const EventsFormContainer = styled.div`
  margin: 5px;
  box-shadow: 0.5px 1px 5px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
`;

const EventsInputContainer = styled.div`
  padding: 5px;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const LeftColumnContainer = styled.div`
  width: 35%;
  margin: 5px;
  span {
    float: right;
  }
`;

const RightColumnContainer = styled.span`
  width: 45%;
  display: flex;
  margin: 5px;
  flex-direction: column;
  #target {
    margin: 2px 0px;
  }
  input {
    font-size: 14px;
  }
`;

const EventsContainerButton = styled.button`
  width: 140px;
  height: 45px;
  font-size: 14px;
  color: var(--text-color);
  margin: 5px;
  background-color: var(--primary-ui);
  border-radius: 5px;
  border: 2px solid var(--primary-ui);
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  label {
    margin: 5px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  display: none;
  height: 0px !important;
  padding: 0px !important;
  margin-bottom: -10px !important;
`;

const StyledInputText = styled(InputText)`
  & .p-inputnumber-spinner {
    display: none;
  }
`;

const SignalPayloadContainer = styled.div.attrs({
  className: "flex justify-content-start flex flex-row gap-3 flex-wrap",
})``;

export const EStyles = {
    EventsFormContainer,
    RowContainer,
    LeftColumnContainer,
    RightColumnContainer,
    EventsContainerButton,
    CheckboxContainer,
    EventsInputContainer,
    StyledDatePicker,
    StyledInputText,
    SignalPayloadContainer
}