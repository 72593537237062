import { useState, useEffect } from "react";

export interface IAuthService {
  getAuthUrl: () => Promise<{ data: { url: string } }>;
}

export const useAuth = (authService: IAuthService) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const authenticate = async () => {
      setLoader(true);
      try {
        let response = await authService.getAuthUrl();
        window.location.assign(response.data.url);
      } catch (err) {
        setError("Failed to authenticate");
      } finally {
        setLoader(false);
      }
    };
    authenticate();
  }, [authService]);

  return { loader, error };
};