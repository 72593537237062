import axiosInstance from "../Utils/Interceptor";

export const EventScheduleService = async(data: Record<string, unknown>) => {
    return axiosInstance.post('/schedules', data ,{apiName : "Schedule Event"} as object);
};
export const UpdateScheduleService = async(data: Record<string, unknown>, id: string | number) => {
    return axiosInstance.put('/schedules/' + id, data ,{apiName : "Edit Schedule"} as object);
};
export const DeleteScheduleService = async(id: string | number) => {
    return axiosInstance.delete('/schedules/' + id,{apiName : "Delete Schedule"} as object);
};