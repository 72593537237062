import React, { useState, useEffect, useRef, FunctionComponent } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useResponsiveTable } from '../../../Utils/useResponsiveTable';
import { ScheduleLogService } from '../../../service/scheduleLogService';
import { createPaginatorTemplate } from '../../../Utils/paginationTemplate';
import { PaginatorTemplateOptions } from 'primereact/paginator';
import { ClipLoader } from 'react-spinners';
import { LStyles } from '../../events/EventsLog/eventlogsStyles';
import scheduleIcon from '../../../assets/images/scheduleIcon.svg'
import { responseEndDateTemplate, responsePayloadTemplate, responseStartDateTemplate } from '../../../Utils/logTemplates';
import {RequestIdTemplate } from '../../events/EventsIdTemplate/events_id_template';
import { CgCloseO } from 'react-icons/cg';
import { DeleteScheduleService } from '../../../service/eventScheduleService';
import { sanitizeRequestId, showToast } from '../../../Utils/Utility';
import { Dialog } from 'primereact/dialog';
import { ScheduleForm } from '../SchedulesForm/schedulesForm';
import moment from 'moment';
import { TbEdit } from 'react-icons/tb';
import useFetchLogs from '../../../Utils/useFetchLogs';
import { BaseEvent } from '../../../types/event';

interface DataTableStateEvent {
    first: number;
    rows: number;
    page?: number;
}

interface RowData {
    request_id: string;
    status: string;
    id: string;
    payload: BaseEvent;
    schedule?: string;
}

export const SchedulesLog: FunctionComponent = () => {
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<RowData | null>(null);
    const [loadSchedules, setLoadSchedules] = useState(true);
    const toast = useRef<Toast>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [publishedEventId, setPublishedEventId] = useState<string>();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [page, setPage] = useState(1);

    const { scrollWidth ,scrollHeight, tableSize} = useResponsiveTable();

    const { loading, eventLogs, totalRecords, pageLinks:rawPageLinks = { prev: false, first: false, last: false, next: false }, fetchLogs } = useFetchLogs(ScheduleLogService);
    const pageLinks = {
        ...rawPageLinks,
        prev: rawPageLinks.prev !== null,
        next: rawPageLinks.next !== null,
        first: rawPageLinks.first !== null,
        last: rawPageLinks.last !== null,
    };
     useEffect(() => {
        if (loadSchedules) {
            fetchLogs(page, rows);
            setLoadSchedules(false);
            setIsLoading(false)
        }

        if (publishedEventId?.includes('Scheduled')) {
            showToast(toast, "success", "Event Scheduled!", `Id : ${publishedEventId.replace('Scheduled', '')}`, true);
        } else if (publishedEventId?.includes('Updated')) {
            showToast(toast, "success", "Event Updated!", `Id : ${publishedEventId.replace('Updated', '')}`, true);
        } else if (publishedEventId?.includes('Cancelled')) {
            showToast(toast, "success", "Event Cancelled!", `Id : ${publishedEventId.replace('Cancelled', '')}`, true);
        }


    }, [loadSchedules, fetchLogs, isLoading, publishedEventId]);

    useEffect(() => {
        fetchLogs(page, rows);
    }, [page, rows, fetchLogs]);

    const onPageChange = (event: DataTableStateEvent): void => {
        setFirst(event.first);
        setRows(event.rows);
        setPage((event.page ?? 0) + 1);
    };
    const paginationTemplate: PaginatorTemplateOptions = createPaginatorTemplate(page, rows, totalRecords, pageLinks, setPage, setRows);

    const handleChildEvent = (type: string, requestId: string) => {
      setLoadSchedules(true);
      setPublishedEventId(type + requestId)
  };

  const responseScheduleDateTemplate = (rowData: RowData) => {
    return (
        <div>
            {rowData?.schedule ? moment(rowData?.schedule).format('MM/DD/YYYY, HH:mm') : 'NULL'}
            {rowData.status !== 'PUBLISHED' &&
                rowData.status !== 'CANCELLED' &&
                rowData.status !== 'FAILED' ? (
                <button
                    className='surface-0 border-none'
                    onClick={() => {
                        setVisibleEdit(true);
                        setSelectedRowData(rowData);
                    }}
                    data-testid="generate-btn"
                >
                    <TbEdit />
                </button>
            ) : (
                ''
            )}
        </div>
    );
};



  const hideDialog = () => {
    setVisible(false);
};
const hideEditDialog = () => {
    setVisibleEdit(false);
};


const accept = async (rowData: RowData) => {
    setIsLoading(true);
    await DeleteScheduleService(rowData.id)
      .then(() => {
        handleChildEvent("Cancelled", rowData.payload?.request_id);
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(toast, "error", "Schedule Cancellation Failed " + error, "", false);
      });
  };

  const confirm2 = (rowData: RowData) => {
    const targetElement = document.querySelector(`#delete-${rowData.id}`);

      if (targetElement instanceof HTMLElement) {
          confirmPopup({
              target: targetElement,
              message: 'Do you want to cancel this schedule?',
              icon: <CgCloseO />,
              defaultFocus: 'reject',
              accept: () => accept(rowData),
              acceptClassName: 'p-button-danger',
              acceptLabel: "Yes",
          });
      } else {
          console.error('Target element not found or is not an HTMLElement');
      }
  };

const renderRequestIdTemplate = (rowData:RowData) => {
  return <RequestIdTemplate rowData={rowData} onConfirm={confirm2} type="schedule" />;
}

    return (
        <>
            {isLoading ? (
                <div className="loader">
                    <ClipLoader color="var(--primary-ui)" loading={isLoading} size={100} aria-label="Publishing" data-testid="loader" className="loader-img" />
                </div>
            ) : (
                <LStyles.EventsLogContainer>
                    <LStyles.HeaderContainer>
                        <Toast ref={toast} position="top-right" />
                        <ConfirmPopup />
                        <LStyles.PageHeader>SCHEDULES</LStyles.PageHeader>
                        <LStyles.ButtonContainer className="m-2">
                            <LStyles.CustomButton onClick={() => setVisible(true)} data-testid="schedule-btn">
                                <img src={scheduleIcon} alt="generateIcon" className="mr-2" />
                                Schedule Event
                            </LStyles.CustomButton>
                        </LStyles.ButtonContainer>
                    </LStyles.HeaderContainer>
                    <LStyles.DataTableContainer>
                        <LStyles.TableContainer>
                            <ScrollPanel style={{ width: scrollWidth , height: scrollHeight}}>
                                <DataTable
                                    value={eventLogs}
                                    loading={loading}
                                    paginator
                                    paginatorTemplate={paginationTemplate}
                                    rows={rows}
                                    totalRecords={totalRecords}
                                    first={first}
                                    onPage={onPageChange}
                                    filterDisplay="menu"
                                    size={tableSize}
                                    sortField="rowId"
                                    sortOrder={-1}
                                    rowsPerPageOptions={[5, 10, 20, 120]}
                                    emptyMessage="No records found."
                                    className={`dataTableClass datatable-bg sm:text-xs md:text-xs lg:text-sm xl:text-md fadein animation-duration-100`}
                                    pt={{paginator:{root:{className:'sm:p-0'}}}}
                                    rowClassName={(rowData) => 'payload' in rowData ? sanitizeRequestId(rowData.payload.request_id) : ''}
                                
                                >
                                      <Column key="payload.signal_name" sortable field="payload.signal_name" header="Signal Name"
                                         />
                                    <Column key="schedule" sortable field="schedule" header="Scheduled Date & Time"
                                         body={responseScheduleDateTemplate} />
                                   <Column key="start_time" sortable field="payload?.start_time" header="Event Start"
                                         body={responseStartDateTemplate} />
                                    <Column key="payload.end_time" sortable field="payload.end_time" header="Event End"
                                         body={responseEndDateTemplate} />
                                    <Column key="payload.signal_payload" sortable field="payload.signal_payload" header="Signal Payload"
                                         body={responsePayloadTemplate}/>
                                    <Column key="payload.area_code" sortable field="payload.area_code" header="Area"
                                         data-testid="hello"/>
                                    <Column key="status" sortable field="status" header="Status"
                                         />
                                         <Column
                                        key="request_id"
                                        field="payload.request_id"
                                        headerStyle={{ backgroundColor: "lightgray" }}
                                        body={renderRequestIdTemplate}
                                    />
                                </DataTable>
                            </ScrollPanel>
                        </LStyles.TableContainer>
                    </LStyles.DataTableContainer>
                    <Dialog
                        visible={visible}
                        className={"w-6"}
                        header="Schedule Event"
                        modal
                        onHide={hideDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask  pt={{ header: {className:'p-2 pl-5' }, headerTitle: {className:'font-bold' } }}>
                        <ScheduleForm
                            mode="create"
                            onPublishEvent={handleChildEvent}
                            setVisible={setVisible}
                            setLoader={setIsLoading}
                        />
                    </Dialog>
                    <Dialog
                        visible={visibleEdit}
                        className={"w-6"}
                        header="Edit Schedule"
                        modal
                        onHide={hideEditDialog}
                        maximizable={window.innerWidth < 768 ? false : true}
                        maximized={window.innerWidth < 768 ? true : false}
                        dismissableMask  pt={{ header: {className:'p-2 pl-5' }, headerTitle: {className:'font-bold' } }}>

                        <ScheduleForm
                            mode="edit"
                            rowData={selectedRowData}
                            onPublishEvent={handleChildEvent}
                            setVisible={setVisibleEdit}
                            setLoader={setIsLoading}
                        />
                    </Dialog>
                </LStyles.EventsLogContainer>
            )}
        </>
    );
};